import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { omit, round } from 'lodash-es'
import { NumericFormat } from 'react-number-format'
import TextField, { propTypes as TextFieldPropTypes } from '../../atoms/TextField/TextField'
import VatIcon from '../../atoms/VatIcon/VatIcon'
import styles from './PercentageField.module.scss'
import Cleave from 'cleave.js/react'
import EditIcon from '../../atoms/Svgs/Edit'
import PercentageInput from 'views/components/atoms/TextField/PercentageInput'
import { $TSFixMe } from 'types/ts-migrate'

const propTypes = {
  ...omit(TextFieldPropTypes, [
    'textarea',
    'leadingIcon',
    'trailingIcon',
    'onLeadingIconClick',
    'onTrailingIconClick',
    'type',
    'placeholder',
    'values',
  ]),
  ...{
    includeVatControl: PropTypes.bool,
    includeVatCalculator: PropTypes.bool,
    vatApplied: PropTypes.bool,
    onToggleChange: PropTypes.func,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Used for VAT control */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    allowNegative: PropTypes.bool,
  },
}

const defaultProps = {
  includeVatControl: false,
  includeVatCalculator: true,
  vatApplied: false,
  onToggleChange: (vatApplied, value) => ({
    vatApplied,
    value,
  }),
  onChange: (e: any) => e,
  allowNegative: false,
}

type State = {
  editExAmount: boolean
  value: number
  initialValue: number
}

class PercentageField extends Component<$TSFixMe, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      editExAmount: false,
      value: 0,
      initialValue: 0,
    }
    this.handlePercentageChange = this.handlePercentageChange.bind(this)
    this.handleToggleChange = this.handleToggleChange.bind(this)
  }

  componentDidMount(): void {
    const { value } = this.props
    const floatValue = parseFloat(value)

    this.setState({
      value: floatValue,
      initialValue: floatValue,
    })
  }

  handlePercentageChange(e: any) {
    const { floatValue } = e

    this.setState({
      value: floatValue,
      initialValue: floatValue,
    })
  }

  handleToggleChange() {
    const { vatApplied } = this.props
    this.props.onToggleChange(!vatApplied)
    this.setState({ editExAmount: false })

    if (vatApplied) {
      this.setState({ value: this.state.initialValue })
    }
  }

  handleBlur = (e: any): void => {
    const { onBlur } = this.props
    onBlur?.(e)
  }

  handleExAmountChange = (e: any): void => {
    const { rawValue } = e.target
    const includingVat = round(rawValue * 1.155, 2)
    // @ts-expect-error
    this.setState({ value: parseFloat(includingVat) })

    this.props.onChange({
      ...e,
      ...{
        target: {
          ...e.target,
          ...{
            value: includingVat,
          },
        },
      },
    })
  }

  handleSubText() {
    const excludingVat = round(this.state.value / 1.155, 2)
    const amount = !isNaN(excludingVat) ? excludingVat : '0.00'
    const props = {
      name: 'percentageField.editExAmount',
      onChange: this.handleExAmountChange,
      placeholder: '0,00',
      options: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        delimiter: ' ',
      },
      value: amount,
    }

    return (
      <span>
        {this.state.editExAmount ? <Cleave {...props} className={styles['inline-input']} /> : amount}% excluding VAT
      </span>
    )
  }

  render(): null | React.ReactElement {
    const {
      id,
      className,
      disabled,
      label,
      name,
      error,
      noOutline,
      dark,
      includeVatControl,
      includeVatCalculator,
      vatApplied,
      value,
      allowNegative,
      autoComplete,
      placeholder,
      ...rest
    } = this.props

    const vatIconProps = { onClick: this.props.disabled ? () => {} : () => this.handleToggleChange() }
    const trailingIcon = !includeVatControl ? null : <VatIcon enabled={vatApplied} {...vatIconProps} />

    return (
      <div className={styles.root}>
        <TextField
          id={id}
          className={className}
          disabled={disabled}
          label={label}
          name={name}
          error={error}
          noOutline={noOutline}
          dark={dark}
          inputComponent={
            <PercentageInput
              onChange={this.handlePercentageChange}
              onBlur={this.handleBlur}
              value={this.state.value}
              placeholder="0%"
              trailingIcon={trailingIcon}
              allowNegative={allowNegative}
              {...rest}
            />
          }
        />
        {includeVatCalculator && vatApplied && (
          <span className={styles['sub-text']}>
            {this.handleSubText()}
            <EditIcon
              className={styles['edit-button']}
              title="Edit amount"
              width={20}
              height={20}
              onClick={() => this.setState({ editExAmount: !this.state.editExAmount })}
            />
          </span>
        )}
      </div>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
PercentageField.propTypes = propTypes
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
PercentageField.defaultProps = defaultProps

export default PercentageField
