import React, { FC, useEffect, useState } from 'react'
import { round } from 'lodash-es'
import EditIcon from '../../atoms/Svgs/Edit'
import CheckIcon from '../../atoms/Icons/Controls/Check'
import ControlledTooltip from '../../atoms/Tooltip/ControlledTooltip'
import styles from './VATCalculator.module.scss'
import { currencyUtils } from 'utils/index'
import { $TSFixMe } from 'types/ts-migrate'
import { NumericFormat } from 'react-number-format'

type Props = {
  vatApplied: boolean
  disabled?: boolean
  currencySymbol?: string
  amount?: number
  onChange: (e: $TSFixMe) => void
}

const VATCalculator: FC<Props> = ({ vatApplied, disabled, currencySymbol = 'R', amount, onChange }) => {
  const [state, setState] = useState({
    editExAmount: false,
    floatValue: '',
    value: amount ? currencyUtils.fromCents(amount) : 0,
  })

  useEffect(() => {
    setState({
      ...state,
      value: amount ? currencyUtils.fromCents(amount) : 0,
    })
  }, [amount])

  const calculateVAT = (value: any, vatApplied: any) => {
    if (vatApplied) {
      const excluding = round(value / 1.155, 2)
      return {
        excluding,
        including: value,
      }
    } else {
      const including = round(value * 1.155, 2)
      return {
        including,
        excluding: value,
      }
    }
  }

  const { excluding } = calculateVAT(state.value, true)
  const formattedAmount = currencyUtils.formatCurrency(currencyUtils.toCents(excluding).getAmount(), true)

  const handleExAmountChange = (e: $TSFixMe) => {
    const { floatValue } = e
    const { including } = calculateVAT(floatValue, false)

    setState({ ...state, floatValue, value: including })
  }

  return vatApplied ? (
    <span className={styles['sub-text']}>
      <span>
        {currencySymbol} &nbsp;
        {state.editExAmount ? (
          <NumericFormat
            className={styles['inline-input']}
            name="currencyField.editExAmount"
            thousandSeparator=" "
            value={excluding}
            placeholder="0,00"
            onValueChange={handleExAmountChange}
            decimalScale={2}
            fixedDecimalScale
            defaultValue={0}
            allowNegative={false}
          />
        ) : (
          formattedAmount
        )}{' '}
        excluding VAT
      </span>
      {!disabled &&
        (state.editExAmount ? (
          <CheckIcon
            className={styles['edit-button']}
            width={20}
            height={20}
            onClick={() => {
              setState({ ...state, editExAmount: !state.editExAmount })
              const value = state.value ? currencyUtils.toCents(state.value).getAmount() : ''
              onChange(value)
            }}
          />
        ) : (
          // @ts-expect-error
          <ControlledTooltip body="Edit amount">
            <EditIcon
              className={styles['edit-button']}
              width={20}
              height={20}
              onClick={() => setState({ ...state, editExAmount: !state.editExAmount })}
            />
          </ControlledTooltip>
        ))}
    </span>
  ) : null
}

export default VATCalculator
